
.grid-x {
  display: flex;
  flex-flow: row wrap
}

.cell.auto {
  flex: 1 1 0px
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto
  }
  .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12, .grid-x > .medium-shrink {
    flex: 0 0 auto
  }
  .grid-x > .medium-shrink {
    width: auto
  }
  .grid-x > .medium-1 {
    width: 8.33333%
  }
  .grid-x > .medium-2 {
    width: 16.66667%
  }
  .grid-x > .medium-3 {
    width: 25%
  }
  .grid-x > .medium-4 {
    width: 33.33333%
  }
  .grid-x > .medium-5 {
    width: 41.66667%
  }
  .grid-x > .medium-6 {
    width: 50%
  }
  .grid-x > .medium-7 {
    width: 58.33333%
  }
  .grid-x > .medium-8 {
    width: 66.66667%
  }
  .grid-x > .medium-9 {
    width: 75%
  }
  .grid-x > .medium-10 {
    width: 83.33333%
  }
  .grid-x > .medium-11 {
    width: 91.66667%
  }
  .grid-x > .medium-12 {
    width: 100%
  }
}

a:focus, a:hover {
  color: $app-color
}

p + .stat {
  margin-top: -1rem
}

ol.no-bullet, ul.no-bullet {
  margin-left: 0;
  list-style: none;
  padding-left: 0 !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: #000 !important;
    text-shadow: none !important
  }
  .show-for-print {
    display: block !important
  }
  .hide-for-print {
    display: none !important
  }
  table.show-for-print {
    display: table !important
  }
  thead.show-for-print {
    display: table-header-group !important
  }
  tbody.show-for-print {
    display: table-row-group !important
  }
  tr.show-for-print {
    display: table-row !important
  }
  td.show-for-print, th.show-for-print {
    display: table-cell !important
  }
  a, a:visited {
    text-decoration: underline
  }
  a[href]:after {
    content: " (" attr(href) ")"
  }
  .ir a:after, a[href^="#"]:after, a[href^="javascript:"]:after {
    content: ""
  }
  abbr[title]:after {
    content: " (" attr(title) ")"
  }
  blockquote, pre {
    border: 1px solid #cccaca;
    page-break-inside: avoid
  }
  thead {
    display: table-header-group
  }
  img, tr {
    page-break-inside: avoid
  }
  img {
    max-width: 100% !important
  }
  @page {
    margin: .5cm
  }
  h2, h3, p {
    orphans: 3;
    widows: 3
  }
  h2, h3 {
    page-break-after: avoid
  }
  .print-break-inside {
    page-break-inside: auto
  }
}

header {
  background-color: #fff
}

i.fas.fa-align-justify{
  padding-left: 10px;
}

.submenu a {
  line-height: 1;
  text-decoration: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
}

.submenu ul.nav-main-tabs li {
  line-height: 1;
  text-decoration: none;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
}

header #moreinfo {
  text-decoration: underline
}

header .main-menu .menu:not(.submenu) .active .active a, header .main-menu .menu:not(.submenu) .active > a {
  color: $app-color;
  background: transparent
}

header .menu:not(.submenu) .active > a, header .menu:not(.submenu) .is-active > a {
  background-color: transparent;
  color: #291d1e
}

header .menu:not(.submenu) .active > a:hover, header .menu:not(.submenu) .is-active > a:hover {
  color: $app-color
}

header .mainmenu .menu:not(.submenu) > li:first-child a {
  font-weight: 700
}

header .mainmenu .menu:not(.submenu) > li:first-child a:after {
  content: "";
  position: absolute;
  right: 0;
  top: .25rem;
  height: 1.5rem;
  width: 1px;
  border-right: 1px solid #dbd9d9
}


header .sorted-menu .bold-letter {
  display: block;
}

header .marketing-label {
  background-color: #faf7f7;
  border-radius: 8px;
  border: 1px solid #ebe8e8;
  color: #291d1e;
  display: inline-block;
  font-size: .6875rem;
  line-height: 1;
  margin-left: 8px;
  padding: 1px 8px
}

header .submenu > .grid-x > .medium-3 {
  max-width: 254px
}

header .submenu > .grid-x > .medium-9 {
  border-left: 2px solid #faf7f7;
  padding-left: 2rem
}

header .submenu .nav-main-tabs li a {
  display: block;
  background: transparent url('./img/arrowRight-grey.svg') no-repeat 94% 20%;
  padding: 0 5rem 1rem 0
}

header .submenu .nav-main-tabs li.active a {
  color: $app-color;
  background-image: url('./img/arrowRight-ci.svg')
}

header .submenu.show-for-large {
  display: none;
  background-color: #fff;
  position: absolute;
  z-index: 10;
  box-shadow: 0 .6rem 1.25rem 0 rgba(0, 0, 0, .07);
  border: .0625rem solid #ebe8e8;
  position: absolute;
  left: 0;
  right: 0;
  width: 1143px;
  margin: auto;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  header .submenu.show-for-large {
    width: 100%;
    left: 0
  }
}

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  header .submenu.show-for-large {
    width: 100%;
    left: 0;
  }
}

//
.sub-menu-not-active, .menu-not-active {
  display: none !important;
}

.sub-menu-active, .menu-active {
  display: block !important;
}

.sub-menu-group-name {
  text-decoration: underline !important;
}

