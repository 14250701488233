$appColor: #e6001e;

// Variables
@import "variables";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.col-table100{
  text-align: center;
  width: 100px;
}

.col-table-custom{
  text-align: center;
  width: 137px;
}

.table-orders {
  background-color: grey;
  color: white;
  font-weight: bold;
  a {
    color: #fff;
  }
}

@import "tardis";
@import "notification";
@import 'menu';
@import "~flatpickr";
@import "~choices.js/src/styles/choices";
