@font-face {
  font-family: 'EuclidFA';
  src: url('./fonts/euclid-fa-ultralight.woff2') format('woff2'), url('./fonts/euclid-fa-ultralight.woff') format('woff'), url('./fonts/euclid-fa-ultralight.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
  unicode-range: 'U+0000-007F';
}
@font-face {
  font-family: 'EuclidFA';
  src: url('./fonts/euclid-fa-ultralight-italic.woff2') format('woff2'), url('./fonts/euclid-fa-ultralight-italic.woff') format('woff'), url('./fonts/euclid-fa-ultralight-italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 100;
  unicode-range: 'U+0000-007F';
}
@font-face {
  font-family: 'EuclidFA';
  src: url('./fonts/euclid-fa-light.woff2') format('woff2'), url('./fonts/euclid-fa-light.woff') format('woff'), url('./fonts/euclid-fa-light.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
  unicode-range: 'U+0000-007F';
}
@font-face {
  font-family: 'EuclidFA';
  src: url('./fonts/euclid-fa-light-italic.woff2') format('woff2'), url('./fonts/euclid-fa-light-italic.woff') format('woff'), url('./fonts/euclid-fa-light-italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 200;
  unicode-range: 'U+0000-007F';
}
@font-face {
  font-family: 'EuclidFA';
  src: url('./fonts/euclid-fa-regular.woff2') format('woff2'), url('./fonts/euclid-fa-regular.woff') format('woff'), url('./fonts/euclid-fa-regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  unicode-range: 'U+0000-007F';
}
@font-face {
  font-family: 'EuclidFA';
  src: url('./fonts/euclid-fa-regular-italic.woff2') format('woff2'), url('./fonts/euclid-fa-regular-italic.woff') format('woff'), url('./fonts/euclid-fa-regular-italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
  unicode-range: 'U+0000-007F';
}
@font-face {
  font-family: 'EuclidFA';
  src: url('./fonts/euclid-fa-medium.woff2') format('woff2'), url('./fonts/euclid-fa-medium.woff') format('woff'), url('./fonts/euclid-fa-medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  unicode-range: 'U+0000-007F';
}
@font-face {
  font-family: 'EuclidFA';
  src: url('./fonts/euclid-fa-medium-italic.woff2') format('woff2'), url('./fonts/euclid-fa-medium-italic.woff') format('woff'), url('./fonts/euclid-fa-medium-italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
  unicode-range: 'U+0000-007F';
}
@font-face {
  font-family: 'EuclidFA';
  src: url('./fonts/euclid-fa-bold.woff2') format('woff2'), url('./fonts/euclid-fa-bold.woff') format('woff'), url('./fonts/euclid-fa-bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  unicode-range: 'U+0000-007F';
}
@font-face {
  font-family: 'EuclidFA';
  src: url('./fonts/euclid-fa-bold-italic.woff2') format('woff2'), url('./fonts/euclid-fa-bold-italic.woff') format('woff'), url('./fonts/euclid-fa-bold-italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
  unicode-range: 'U+0000-007F';
}
