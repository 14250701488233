@import "fonts";

$color-deep-black: #000000;
$color-light-grey: #ebebeb;
$color-grey: #cccaca;
$color-primary-red: #e6001e;
$color-dark-red: #b90f2d;
$color-white: #ffffff;
$color-seagreen: #00a0c3;

html, body, div, p, span, h1, h2, h3, h4, h5, h6, input, button, label, a, em, strong {
  font-family: "EuclidFA", sans-serif !important;
}

body {
  background-color: #FAF7F7;
  font-size: 16px;
}

a {
  color: $color-deep-black;
}

h1, h2, h3, h4, h5, h6 {
  color: #444;
  letter-spacing: -0.5px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  font-family: 'Open Sans';
  font-weight: 600;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

table a:hover {
  color: $app-color;
  text-decoration: none;
}

input[type=submit], button[type=submit], input[type="button"] {
  background-color: $app-color;
  border-color: $app-color;
}

input[type=submit]:hover, button[type=submit], input[type="button"] {
  background: $app-color;
  border-color: $app-color;

}

.container-bg-red {
  background-color: $app-color;
  color: #fff;
  line-height: 36px;
}

.container-bg-red a {
  color: #fff;
}

.container-bg-white {
  background-color: #fff;
  color: #000;
}

.container-bg-white a {
  color: #000;
}

.infobar {
  line-height: 36px;
}

.infobar a {
  text-decoration: none;
}

.infobar i {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

.toolbar {
  padding: 25px 0 15px 0;

  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
}

.toolbar a {
  text-decoration: none;
}

.toolbar a:hover {
  color: $app-color;
}

.main {
  margin-top: 15px;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 10px;

  &.__transparent {
    background-color: inherit;
  }

  &.__white {
    background-color: #fff;
  }
}

ul.inline {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.inline li {
  display: inline-block;
  padding: 0 10px;
}

ul.inline-separator li::before {
  content: "|";
  display: inline-block;
  margin-right: 10px;
  margin-left: -10px;
}

ul.inline-separator li:first-of-type::before {
  display: none;
}

.scrollable-list {
  height: 200px;
  overflow-x: auto;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: $app-color;
}

.nav-pills .nav-link {
  background-color: $color-light-grey;
  margin: 0.2em;
}

.accordion-content {
  padding-left: 20%;
}

.card-header {
  padding: 0;
  background-color: $app-color;
}

.card-header .btn-link {
  color: white;
}

.logs tr td:last-child {
  word-break: break-all;
}

.table .thead-dark th {
  color: $color-deep-black;
  background-color: $color-light-grey;
  border-color: $color-light-grey;
}

.btn-outline-dark {
  background: transparent !important;
  border: 1px solid $color-deep-black !important;
  border-radius: 0 !important;
  color: $color-deep-black !important;
  text-transform: none;
  box-shadow: none;
}

.btn-outline-red {
  background: transparent !important;
  border: 1px solid $app-color !important;
  border-radius: 0 !important;
  color: $app-color !important;
  text-transform: none;
  box-shadow: none;
}

.btn-app-color {
  background-color: $app-color;
  color: #FFFFFF;
}

.btn-outline-red:hover {
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  opacity: 1 !important;
  transition: all 0.2s linear !important;
  -moz-transition: all 0.2s linear !important;
  -webkit-transition: all 0.2s linear !important;
  -o-transition: all 0.2s linear !important;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -o-border-radius: 2px !important;
  border: 2px solid rgba(255, 255, 255, 0.75);
  box-shadow: none;
}

.btn-outline-dark:hover {
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  opacity: 1 !important;
  transition: all 0.2s linear !important;
  -moz-transition: all 0.2s linear !important;
  -webkit-transition: all 0.2s linear !important;
  -o-transition: all 0.2s linear !important;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -o-border-radius: 2px !important;
  border: 2px solid rgba(255, 255, 255, 0.75);
  box-shadow: none;
}

.page-link {
  color: $app-color;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: $app-color;
  border-color: $app-color;
}

.page-link:hover {
  z-index: 2;
  color: $app-color;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus, .btn-outline-red:focus, .btn-outline-red.focus {
  box-shadow: none !important;
}

.page-link:focus, .btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: none !important;
}

.article-search ul {
  max-height: 200px;
  overflow: scroll;
  overflow-x: hidden;
}

.article-search ul li {
  padding: 0.2rem 1.25rem;
}

.article-search .list-group-item:first-child,
.article-search .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-toggle {
  width: 100%;
}

.typeahead__search:focus {
  height: 100%;
}

.productGroupImage {
  float: left;
  margin: 20px 30px 10px 0;
  max-width: 240px;
  max-height: 200px;
}

.productGroupDescription {
  width: 100%;
}

.productName {
  margin-top: 20px;
}

.fixed-row {
  max-width: none;
  box-sizing: border-box;
  margin: 0;
}

.productOptionsGroup {
  padding-top: 1em;
  padding-left: 2em;
}

.productOptions {
  padding-top: 1em;
  padding-left: 2em;
}

.article-list #productGroupImage {
  float: left;
  margin: 0 10px 10px 0;
}

.productPrice {
  font-size: 28px;
  color: $app-color;
}

#carousel-example-generic {
  display: inline-block;
}

html, body, div, applet, object, iframe,
img,
time, mark, audio, video {
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

.shadowed {
  box-shadow: 0 0 8px 1px #b3b6b6;
}

#carousel .animate {
  animation: animate 1s;
}

@-webkit-keyframes slide-over {
  from {
    opacity: 0;
  }
  to {
    opacity: 100%;
  }
}

#carousel {
  height: 200px;
  width: 290px;
  margin: 20px auto 0;
  overflow: hidden;
  position: relative;
}

@media (max-width: 768px) {
  #carousel {
    height: 300px;
    width: 600px;
  }
}

@media (max-width: 480px) {
  #carousel {
    height: 225px;
    width: 450px;
  }
}

#carousel .slides {
  -webkit-animation: slide-over 1s;
  border: 5px solid white;
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#carousel .slides .hero1 {
  background-size: 100% auto;
}

#carousel .slides .hero2 {
  background-size: 100% auto;
}

#carousel .slides .hero3 {
  background-size: 100% auto;
}

#carousel .hero-nav {
  color: white;
  cursor: pointer;
  font-size: 30px;
  padding: 10px;
  position: absolute;
  top: 50%;
  text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
  #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
  transform: translate(0, -50%);
  transition: ease-out 0.6s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#carousel .hero-nav:hover {
  transition: ease-in 0.6s;
}

#carousel .prev {
  left: 5px;
}

#carousel .next {
  right: 5px;
}

.button-container {
  text-align: center;
}

.button-container .hero-radio-button {
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
  user-select: none;
}

.button-container .selected {
  background: red;
}

.button .icon img {
  margin: 4px 0 0 3px;
}

.button .text {
  color: white;
  float: right;
  font-size: 15px;
  text-shadow: 0 0 2px black;
  text-transform: uppercase;
  position: absolute;
  margin: 0 auto;
  top: 15px;
  left: 12px;
}

.gallery-images {
  max-height: 75px;
}

.carousel-images {
  max-height: 200px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5); /*dim the background*/
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
