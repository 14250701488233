.notifications {
  list-style: none;
  padding: 0;

  .notification {
    padding: 1em 1.25em;
    background-color: white;
    border-bottom: 1px solid;
  }
}

.notifications-badge {
  .badge-danger {
    background-color: $app-color;
  }
}
