
// Body
$body-bg: #f5f8fa;

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
// built from webpack
$app-color: $appColor;
